// formkit.config.js
import { generateClasses } from "@formkit/themes";
import { it, en, de, fr, es, zh } from "@formkit/i18n";

const textualInput = {
  wrapper: "flex flex-col",
  input: `
    appearance-none rounded-none
    text-skin-base text-body-big/snug placeholder:text-skin-muted 
    bg-transparent
    py-xs w-full max-w-full !min-h-0
    border-0 border-b border-current
    focus:outline-none focus:shadow-none
    formkit-invalid:!text-skin-danger placeholder:formkit-invalid:!text-current 
    transition-colors duration-300 ease-in-out
  `,
};

export default {
  // Define additional locales
  locales: { it, en, de, fr, es, zh },
  // Define the active locale
  locale: "it",
  config: {
    classes: generateClasses({
      global: {
        form: "relative top-0 left-0 flex flex-col items-stretch gap-2xl w-full",
        outer: "formkit-disabled:opacity-40 w-full formkit-disabled:opacity-50",
        label: "text-skin-muted text-body/none",
        messages:
          "list-none flex flex-col justify-start items-stretch gap-xs mt-xs",
        message:
          "text-skin-muted formkit-invalid:!text-skin-danger formkit-errors:!text-skin-danger text-body-small",
        help: "text-body-small text-skin-muted mt-xs",
      },
      form: {
        messages:
          "list-none flex flex-col justify-start items-stretch gap-xs mt-xs",
        message:
          "!text-body-big text-skin-muted formkit-invalid:!text-skin-danger formkit-errors:!text-skin-danger",
      },
      text: textualInput,
      email: textualInput,
      password: textualInput,
      textarea: textualInput,
      select: {
        outer: "[&[data-empty=true]_select]:!text-skin-muted",
        wrapper: textualInput.wrapper,
        input: `${textualInput.input} data-[placeholder=true]:text-skin-inverted/60`,
      },
      checkbox: {
        options: "flex flex-col gap-xs items-start justify-start",
        outer: "formkit-checked:!pointer-events-none",
        wrapper: "flex flex-row items-baseline gap-sm !cursor-pointer",
        input: `appearance-none relative top-0 left-0 overflow-hidden 
        flex jutify-center items-center flex-none
        w-4 h-4 border border-current rounded-sm 
        text-skin-base 
        bg-transparent checked:bg-skin-inverse 
        after:absolute after:inset-0.5 
         after:rounded-full checked:after:bg-skin-base 
        disabled:text-skin-muted disabled:!pointer-events-none`,
        label: "select-none !leading-relaxed",
      },
      radio: {
        outer: "formkit-checked:!pointer-events-none",
        wrapper: "flex flex-row items-baseline gap-sm",
        input: `appearance-none relative top-0 left-0 overflow-hidden 
        flex jutify-center items-center flex-none
        w-4 h-4 border border-current rounded-full 
        text-skin-base 
        bg-transparent checked:bg-skin-inverse 
        after:absolute after:inset-0.5 after:rounded-full checked:after:bg-skin-base 
        disabled:text-skin-muted disabled:!pointer-events-none 
        cursor-pointer formkit-disabled:!cursor-default formkit-checked:!cursor-default`,
        label:
          "select-none cursor-pointer formkit-disabled:!cursor-default formkit-checked:!cursor-default !leading-relaxed",
      },
    }),
  },
};
